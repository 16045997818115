import Landing_Header from "./Landing_Header";
import Content_1 from "./Content_1";
import Content_main from "./Content_main";
import Footer from "./Footer";

export default function LandingPage() {
    return(
        <>
            <Landing_Header/>
            <Content_1/>
            <Content_main/>
            <Footer/>
        </>
    )
}